import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CompanyNav from '../components/companyNav';
import {
  H1White, H2White, H3White,
} from '../components/typography';
import 'react-multi-carousel/lib/styles.css';
import Arrow from '../images/arrow-right.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  margin-bottom: 50px;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledH1White = styled(H1White)`
  margin-bottom: 15px;

  @media (min-width: 64em) {
    margin-bottom: 73px;
  }
`;

const StyledDescription = styled.div`
  color: var(--white);
  font-size: 1.125rem;
  margin-bottom: 59px;
`;

const TR = styled.tr`
  border-top: 1px solid var(--white);
`;

const TD = styled.td`
  padding-top: 13px;
  padding-bottom: 13px;

  @media (min-width: 64em) {
    min-width: 196px;
  }

  span {
    font-size: 12px;
  }
`;

const StyledImg = styled(Img)`
  height: 504px;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 504px;
  margin-bottom: 50px;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const StyledImageWithOpacity = styled(Img)`
  height: 504px;
  opacity: 0.3;
  
  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity .5s ease;
  }
`;

const StyledH3White = styled(H3White)`
  text-align: right;
  line-height: 54px;
`;

const StyledLinkWithHover = styled(Link)`
  &:hover,
  &:focus {
    h3 {
      color: var(--blue);
      transition: color .5s ease;
    }

    svg {
      fill: var(--blue);
      transition: fill .5s ease;
    }
  }

  svg {
    fill: var(--white);
  }

  @media (max-width: 64em) {
    display: none;
  }
`;

const StyledAnchorLinkWithHover = styled(StyledLinkWithHover)`
  display: none;

  @media (max-width: 64em) {
    display: block;
    margin-top: 20px;
  }
`;

const StyledArrow = styled(Arrow)`
  width: 21px;
  height: 14px;
`;

const StyledArrowLeft = styled(StyledArrow)`
  fill: var(--white);
  transform: rotate(-180deg);
`;

const Table = styled.table`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  
  @media (max-width: 64em) {
    width: 100%;
  }
`;

const StyledCustomArrowRight = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  right: calc(2.4% + 1px);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill .5s ease;
  }
`;

const StyledCustomArrowLeft = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  left: calc(2.4% + 1px);
  transform: rotate(-180deg);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill .5s ease;
  }
`;

const Team = ({ data, onClick }) => {
  const teamMember = data.datoCmsTeam;
  const teamMembers = data.allDatoCmsTeam.edges;
  const filteredTeamMembers = teamMembers.filter(({ node: team }) => team.name !== teamMember.name);
  const randomTeamMember = filteredTeamMembers[
    Math.floor(Math.random() * filteredTeamMembers.length)
  ];

  const teamList = data.allDatoCmsTeam.edges;
  // eslint-disable-next-line no-shadow
  teamList.sort((
    { node: team },
  ) => (team.id === teamMember.id ? -1 : 0));

  return (
    <Layout>
      <SEO title={teamMember.name} />
      <Grid>
        <CompanyNav />
      </Grid>
      <SliderContainer>
        <Carousel
          responsive={responsive}
          customRightArrow={<StyledCustomArrowRight onClick={() => onClick()} />}
          customLeftArrow={<StyledCustomArrowLeft onClick={() => onClick()} />}
        >
          {teamList.map(({ node: team }) => (
            <Link to={`/team/${team.slug}`} key={team.id}>
              {team.avatar && team.id === teamMember.id
                ? (
                  <StyledImg fluid={{ ...team.avatar.fluid, aspectRatio: 1 }} key={team.id} />
                ) : (
                  <StyledImageWithOpacity
                    fluid={{ ...team.avatar.fluid, aspectRatio: 1 }}
                    key={team.id}
                  />
                )}
            </Link>
          ))}
        </Carousel>
      </SliderContainer>
      <StyledGrid fluid>
        <Row>
          <Col lg={6} md={6} xs={12} lgOffset={1}>
            <StyledH1White>{teamMember.name}</StyledH1White>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <StyledLinkWithHover to={`/team/${randomTeamMember.node.slug}`}>
              <StyledH3White>
                {randomTeamMember.node.name}
                {' '}
                <StyledArrow />
              </StyledH3White>
            </StyledLinkWithHover>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={12} lgOffset={1}>
            <StyledDescription>
              {teamMember.description}
            </StyledDescription>
            <StyledLinkWithHover to="/company/#team">
              <H3White>
                <StyledArrowLeft />
                {' '}
                our team
              </H3White>
            </StyledLinkWithHover>
          </Col>
          <Col lg={4} md={4} xs={12}>
            <H2White>key projects</H2White>
            <Table>
              <tbody>
                {teamMember.project.map(({
                  id, title, started, slug,
                }) => (
                  <TR key={id}>
                    <TD>
                      {started}
                    </TD>
                    <TD>
                      <Link to={`/projects/${slug}`}>
                        {title}
                      </Link>
                    </TD>
                  </TR>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col xs={12}>
            <StyledAnchorLinkWithHover to="/company/#team">
              <H3White>
                <StyledArrowLeft />
                {' '}
                our team
              </H3White>
            </StyledAnchorLinkWithHover>
          </Col>
        </Row>
      </StyledGrid>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery($slug: String!) {
    datoCmsTeam(slug: { eq: $slug }) {
      id
      name
      description
      avatar {
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes
        }
      }
      project {
        id
        title
        started
        slug
        description
      }
    }
    allDatoCmsTeam(
      sort: {
        fields: [meta___publishedAt],
        order: DESC
      }
      filter: {  hasprofile: { eq: true }}
    ) {
      edges {
        node {
          id
          name
          title
          slug
          avatar {
            fluid(maxHeight: 500) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;

Team.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.shape().isRequired,
};

export default Team;
